<template>
  <div id="trip-edit">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />添加司机
        </van-col>
        <van-col
          span="14"
          class="header-title"
          @click="onCreateDriverClick"
          style="text-align: right"
        >
          完成
        </van-col>
      </van-row>
    </div>
    <div class="trip-item-body">
      <div class="item-body-title">司机信息</div>
      <van-row class="input-container">
        <van-col span="6">
          <div class="input-text">司机姓名</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            type="text"
            v-model="driver.name"
            placeholder="请输入司机姓名"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="6">
          <div class="input-text">司机年龄</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            type="text"
            v-model="driver.age"
            placeholder="请输入司机年龄"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="6">
          <div class="input-text">司机电话</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            type="number"
            v-model="driver.phone"
            placeholder="请输入司机电话"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="6">
          <div class="input-text">身份证号</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            type="number"
            v-model="driver.id_no"
            placeholder="请输入司机身份证号"
          />
        </van-col>
      </van-row>
      <van-row class="input-container" @click="showLicenseTypes = true">
        <van-col span="6">
          <div class="input-text">驾照类型</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            disabled
            type="text"
            v-model="driver.license_type"
            placeholder="请选择"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="6">
          <div class="input-text">驾龄(年)</div>
        </van-col>
        <van-col span="18" class="input-item">
          <input
            type="number"
            v-model="driver.driver_age"
            placeholder="请输入驾龄"
          />
        </van-col>
      </van-row>
      <van-popup
        v-model="showLicenseTypes"
        closeable
        close-icon="close"
        :style="{ height: '30%' }"
        position="bottom"
        ><div class="select-container">
          <div class="select-title">选择驾照类型</div>
          <div
            class="select-item"
            v-for="(i, index) in license_types"
            :key="index"
            @click="selectLicenseTypes(i)"
          >
            {{ i.value }}
          </div>
        </div>
      </van-popup>

      <div class="btn" @click="onCreateDriverClick">完成</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import * as validate from "@/utils/validate";
export default {
  name: "driver-add",
  components: {},
  data() {
    return {
      license_types: [
        { value: "A1" },
        { value: "A2" },
        { value: "A3" },
        { value: "B1" },
        { value: "B2" },
        { value: "C1" },
        { value: "C2" },
        { value: "C3" },
        { value: "C4" },
      ],
      loading: true,
      finished: false,
      refreshing: false,
      showLicenseTypes: false,
      driver: {
        travel_company_id: "",
        name: "",
        id_no: "",
        age: "",
        driver_age: "",
        phone: "",
        license_type: "",
      },
      company: {},
    };
  },
  created() {
    this.get_current_company();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_current_company() {
      const res = await this.$apis.get_current_company();
      this.company = res.data.company;
    },
    selectLicenseTypes(item) {
      this.driver.license_type = item.value;
      this.showLicenseTypes = false;
    },
    async onCreateDriverClick() {
      if (!this.driver.name) {
        Toast("请填写姓名");
        return false;
      }
      if (!/(^[1-9]\d*$)/.test(this.driver.age)) {
        Toast("请输入正确的年龄");
        return;
      }
      if (!validate.isPhoneAvailable(this.driver.phone)) {
        Toast("请输入正确的手机号");
        return false;
      }
      if (!this.driver.id_no) {
        Toast("请填写司机身份证号");
        return false;
      }
      if (!validate.checkIDCard(this.driver.id_no)) {
        Toast("请输入正确的身份证号");
        return false;
      }
      if (!this.driver.age) {
        Toast("请填写司机年龄");
        return false;
      }
      if (!this.driver.driver_age) {
        Toast("请填写司机驾龄");
        return false;
      }
      if (!/(^[1-9]\d*$)/.test(this.driver.driver_age)) {
        Toast("请输入正确的驾龄");
        return;
      }
      const data = {
        driver: this.driver,
      };
      data.driver.travel_company_id = this.company.id;
      const res = await this.$apis.post_driver(data);
      if (res.status == 200) {
        Toast("创建成功！");
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
#trip-edit {
  background: #f3f3f3;
  height: 100vh;
  overflow-y: hidden;
  height: 100vh;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-item-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: relative;
  background-color: #fff;
}
.item-body-title {
  font-size: 0.3rem;
  color: #333333;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
.input-container {
  background: #eaeaea;
  border-radius: 24px;
  border-radius: 24px;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.4rem;
  margin-top: 0.3rem;
}
.input-text {
  text-align: left;
  font-size: 12px;
  color: #333333;
}
.input-item > input {
  background: #eaeaea;
  width: 70%;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333;
  font-size: 12px;
  float: right;
  text-align: left;
}
.input-item > input::placeholder {
  font-size: 12px;
  color: #b1b1b1;
  text-align: left;
}
.xianlu-container {
  margin-top: 0.4rem;
}
.trip-step {
}
.add_icon {
  font-size: 0.5rem;
}
.trip-step input::placeholder {
  color: #999;
}
.select-container {
  height: 100%;
  background: #fff;
}
.select-item {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  height: 0.8rem;
  line-height: 0.8rem;
}
.select-item:nth-child(even) {
  background: #f4f3f3;
}
.select-item:nth-child(odd) {
  background: #fff;
}
.select-title {
  font-size: 0.3rem;
  text-align: center;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
.upload-container {
  margin-top: 20px;
  /* border-radius: 6px; */
  text-align: left;
}
.upload-container img {
  /* margin-top:20px; */
  border-radius: 6px;
}
.input-item input:not(:placeholder-shown) {
  opacity: 1;
  -webkit-text-fill-color: #333;
}
.van-popup {
  background-color: rgba(216, 216, 216, 0) !important;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
